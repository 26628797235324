import BreakdownAndTowEditor from './BreakdownAndTowEditor';
import MapIcon from '@mui/icons-material/Map';
import { AuthComponent } from 'auth/permissions/authComponent';
import {
  CASE_BREAKDOWN_LOCATION_PERMISSION,
  useBreakdownLocationPermissions,
  useTowDestinationPermissions,
} from './config';
import { CaseDetailsContext } from 'features/Cases/contexts/CaseDetails';
import {
  DynamicExpandablePanel,
  ExpandablePanelEditOptions,
} from 'components/Dynamic/DynamicExpandablePanel';
import { ErrorCard, LoaderCard } from 'components/Cards';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useGetBreakdownLocationByUUID, useGetTowDestinationsByUUID } from './BreakdownAndTowQuery';
import SkeletonLoader from 'components/SkeletonLoader';

interface Props {
  caseUUID: string;
  editOptions?: ExpandablePanelEditOptions;
}

const BreakdownAndTow: FC<Props> = ({ caseUUID, editOptions }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState(false);
  const {
    data: breakdownLocationData,
    isLoading: breakdownLocationLoading,
    isError: isBreakdownLocationError,
  } = useGetBreakdownLocationByUUID(caseUUID, expanded);
  const {
    data: towDestinationData,
    isLoading: towDestinationLoading,
    isError: isTowDestinationError,
  } = useGetTowDestinationsByUUID(caseUUID, expanded);

  const { allSectionsExpanded, isEditingSection, setIsEditingSection } =
    useContext(CaseDetailsContext);

  useEffect(() => {
    setExpanded(allSectionsExpanded);
  }, [allSectionsExpanded]);

  const onExpanded = (isExpanded: boolean) => () => {
    setExpanded(!isExpanded);
  };

  const handleEdit = () => {
    setEditMode(true);
    setIsEditingSection(true);
  };

  const isLoading = breakdownLocationLoading || towDestinationLoading;
  const isError = isBreakdownLocationError || isTowDestinationError;

  const disableEdit = useMemo(
    () => isLoading || isError || editOptions?.disabled,
    [isLoading, isError, editOptions?.disabled],
  );

  const { permissions: towDestinationPermissions } = useTowDestinationPermissions();
  const { permissions: breakdownLocationPermissions } = useBreakdownLocationPermissions();
  const isTowDestinationCreatable =
    towDestinationPermissions?.create &&
    (towDestinationData == undefined || towDestinationData.length == 0);

  return (
    <DynamicExpandablePanel
      title="Breakdown Location & Tow Destinations"
      icon={<MapIcon />}
      onExpanded={onExpanded(expanded)}
      expanded={expanded}
      actions={{
        isEditMode: isEditMode,
        onEdit:
          breakdownLocationPermissions?.update || isTowDestinationCreatable
            ? () => handleEdit()
            : undefined,
      }}
      data-testid="BreakdownLocationAndTowDestinationsPanel"
      isEditingSection={isEditingSection}
      editOptions={{
        ...editOptions,
        disabled: disableEdit,
      }}
    >
      {isLoading && <LoaderCard label="Loading Breakdown Location & Tow Destinations" />}

      {isError && (
        <ErrorCard label="An Error Has Occurred While Retrieving Breakdown Location & Tow Destinations" />
      )}

      {!isLoading && breakdownLocationData && towDestinationData && (
        <BreakdownAndTowEditor
          breakdownLocationData={breakdownLocationData}
          towDestinationData={towDestinationData}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          caseUUID={caseUUID}
          setIsEditingSection={setIsEditingSection}
        />
      )}
    </DynamicExpandablePanel>
  );
};

export default AuthComponent(
  CASE_BREAKDOWN_LOCATION_PERMISSION,
  BreakdownAndTow,
  <SkeletonLoader loading height={66} variant="rectangular" />,
);
