import { useEffect, useContext, useMemo, useState, useCallback } from 'react';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import {
  DutyTypes,
  ServiceProvidedDto,
  ServiceSetupDto,
  CreateServiceSetupRequestDto,
  PricingTypes,
  PricingRecordDto,
} from 'api/setup/manageServicesSetupApi';
import { ControlledFormTable } from 'components/ControlledForm/ControlledFormTable';
import DynamicFormDeleteAction from 'components/Dynamic/DynamicFormDeleteAction/DynamicFormDeleteAction';
import { DynamicExpandableFormActions } from 'components/Dynamic/DynamicExpandableFormActions';
import { Context } from '../components/PricingSetupProvider';
import { usePricingServiceRatesConfig } from '../hook/usePricingServiceRatesConfig';
import {
  getUpdateServiceSetupData,
  getCreateServicesConfiguration,
  getCreateServiceSetupData,
} from '../helper';
import ServiceItemsDialog from '../components/ServiceItemsDialog';
import { defaultServiceCodes, serviceRatesValidationSchema } from '../PricingSetupConfig';
import {
  useCreateServicesSetupBatch,
  useUpdateServicesSetupBatch,
  useCreateServiceSetup,
  useDeleteServiceSetup,
  ServiceSetupData,
  QUERY_SERVICE_SETUP_ALL,
  QUERY_GET_PRICING_INFO,
} from '../PricingSetupQuery';
import { useParams, useNavigate } from 'react-router-dom';
import { changeTime } from 'utils/convertDate';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from 'react-query';
import { isEqual } from 'lodash';

interface Props {
  data?: ServiceSetupDto[];
  dutyType: DutyTypes;
  serviceItems?: ServiceProvidedDto[];
  isCreationMode?: boolean;
  isEditMode?: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  toIndividualScreen?: (uuid: string, params?: Record<string, string>) => void;
  tabIndex: number;
  generalInfoHookForm: UseFormReturn<Partial<PricingRecordDto>>;
  onSave: () => void;
}

const PricingServiceRatesEditor = ({
  data,
  dutyType,
  serviceItems,
  isCreationMode,
  isEditMode = false,
  setEditMode,
  toIndividualScreen,
  tabIndex,
  generalInfoHookForm,
  onSave,
}: Props) => {
  let originalData: ServiceSetupData = {
    startDateTime: '',
    endDateTime: '',
    description: '',
    duty: DutyTypes.LIGHT,
    services: [],
  };
  const [open, setOpen] = useState(false);
  const [addedServiceItems, setAddedServiceItems] = useState<string[]>([]);
  const [selectedServiceItems, setSelectedServiceItems] = useState<string[]>([]);
  const [deletedServiceItems, setDeletedServiceItems] = useState<string[]>([]);
  const [resetTrigger, setResetTrigger] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationType>();
  const { uuid } = useParams();
  const theme = useTheme();
  const { mutateAsync: mutateCreateServicesSetupBatch } = useCreateServicesSetupBatch();
  const { mutateAsync: mutateUpdatesServicesSetupBatch } = useUpdateServicesSetupBatch();
  const { mutateAsync: mutateCreateServiceSetup } = useCreateServiceSetup();
  const { mutateAsync: mutateDeleteServiceSetup } = useDeleteServiceSetup();
  const {
    handleSubmit: handleGeneralInfoSubmit,
    reset: generalInfoReset,
    formState: { isDirty: isGeneralInfoDirty, dirtyFields: generalInfoDirtyFields },
  } = generalInfoHookForm;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const stickyOffset = useMemo(
    () => (isCreationMode ? undefined : theme.custom.stickyOffset),
    [isCreationMode, theme.custom.stickyOffset],
  );

  const { entityType, handleIsServiceRatesDirty, setIsEditingSection } = useContext(Context);
  const { pricingServiceRatesFields } = usePricingServiceRatesConfig({ entityType });

  const hookForm = useForm<ServiceSetupData>({
    resolver: yupResolver(serviceRatesValidationSchema),
  });

  if (data && data.length) {
    originalData = getUpdateServiceSetupData(data);
  }

  const { control, reset, formState, watch } = hookForm;
  const { isDirty: isServiceRatesDirty } = formState;

  const { fields, append, remove } = useFieldArray<ServiceSetupData>({
    control: control,
    name: 'services',
  });

  const serviceRatesWatch = watch('services');

  const hasT6Changes = useCallback(() => {
    const hasT6Service = serviceRatesWatch?.find((item) => item.serviceCode === 'T6');
    const hasT6ServiceFromData = data?.find((item) => item.serviceProvided.code === 'T6');

    const hasT6PriceCapChanged =
      hasT6ServiceFromData?.priceCap?.toString() !== hasT6Service?.priceCap;
    const hasT6ClubRateChanged =
      hasT6ServiceFromData?.overridingPricePerUnit?.toString() !==
      hasT6Service?.overridingPricePerUnit;
    const hasT6BaseRateChanged =
      hasT6ServiceFromData?.pricePerUnit?.toString() !== hasT6Service?.pricePerUnit;
    const hasT6PriceTypeChanged = hasT6ServiceFromData?.pricingType !== hasT6Service?.pricingType;
    const hasT6BillableChanged = hasT6ServiceFromData?.billable !== hasT6Service?.billable;

    const hasAnythingChanged =
      hasT6PriceCapChanged ||
      hasT6ClubRateChanged ||
      hasT6BaseRateChanged ||
      hasT6PriceTypeChanged ||
      hasT6BillableChanged;

    return hasT6Service && hasAnythingChanged;
  }, [serviceRatesWatch, data]);

  const hasT6Values = useCallback(() => {
    const hasT6Service = serviceRatesWatch?.find((item) => item.serviceCode === 'T6');
    const hasT6PriceCap = !!hasT6Service?.priceCap;
    const hasT6ClubRate = !!hasT6Service?.overridingPricePerUnit;
    const hasT6BaseRate = hasT6Service?.pricePerUnit !== 0;
    const hasT6PriceType = hasT6Service?.pricingType !== 'AT_COST';

    const hasAnythingChanged = hasT6PriceCap || hasT6ClubRate || hasT6BaseRate || hasT6PriceType;

    return hasT6Service && hasAnythingChanged;
  }, [serviceRatesWatch]);

  useEffect(() => {
    const getDefaultValues = () => {
      if (data && data.length) {
        return getUpdateServiceSetupData(data);
      } else {
        if (isEditMode) {
          return getCreateServiceSetupData(
            serviceItems || [],
            entityType,
            uuid || '',
            dutyType,
            defaultServiceCodes,
          );
        }
      }
    };

    const defaultValues = getDefaultValues();
    reset(defaultValues);
  }, [reset, data, serviceItems, entityType, uuid, dutyType, isEditMode]);

  useEffect(() => {
    handleIsServiceRatesDirty?.(tabIndex, isServiceRatesDirty);
  }, [isServiceRatesDirty, handleIsServiceRatesDirty, tabIndex]);

  useEffect(() => {
    if (data && data.length > 0) {
      const serviceCodesFromData = data.map((item) => item.serviceProvided.code);
      setAddedServiceItems(serviceCodesFromData);
    } else {
      setAddedServiceItems(defaultServiceCodes);
    }
  }, [data]);

  const handleResetSelect = () => {
    setResetTrigger((current) => !current);
  };

  const onAddServices = (serviceCodes: string[]) => {
    const { services } = getCreateServicesConfiguration(
      serviceItems || [],
      entityType,
      uuid || '',
      serviceCodes,
    );

    append(services);
    setAddedServiceItems((current) => [...current, ...serviceCodes]);
    setDeletedServiceItems((current) => current.filter((item) => !serviceCodes.includes(item)));
  };

  const onDeleteServices = () => {
    const itemsToDelete = selectedServiceItems.filter((x) => !deletedServiceItems.includes(x));
    const indexes = itemsToDelete.map((service) => {
      return fields.findIndex((field) => field.uuid === service);
    });

    const deletedServiceCodes = itemsToDelete.map((service) => {
      const field = fields.find((field) => field.uuid === service);
      return field?.serviceCode;
    });

    remove(indexes);
    setDeletedServiceItems((current) => [...current, ...itemsToDelete]);
    setAddedServiceItems((current) =>
      current.filter((item) => !deletedServiceCodes.includes(item)),
    );
    setSelectedServiceItems([]);
    handleResetSelect();
  };

  const onSubmitAll = (formData: ServiceSetupData) => {
    handleGeneralInfoSubmit((generalInfoData) => {
      onSubmitServiceRates(generalInfoData, formData);
    })();
  };

  const onSubmitServiceRates = async (
    generalInfoData: Partial<PricingRecordDto>,
    formData: ServiceSetupData,
  ) => {
    const { startDateTime, endDateTime, description } = generalInfoData;
    const updatedStartDateTime = changeTime(startDateTime, 'T00:00:00Z');
    const updatedEndDateTime = changeTime(endDateTime, 'T23:59:59Z');

    const updatedData: ServiceSetupData = {
      ...formData,
      startDateTime: updatedStartDateTime,
      endDateTime: updatedEndDateTime,
      description: description || '',
      duty: dutyType,
    };

    if (
      isCreationMode ||
      (data && data.length === 0) ||
      generalInfoDirtyFields.startDateTime ||
      generalInfoDirtyFields.endDateTime
    ) {
      try {
        await mutateCreateServicesSetupBatch(updatedData);
        setEditMode(false);
        setIsEditingSection(false);
        queryClient.invalidateQueries([QUERY_SERVICE_SETUP_ALL]);
        queryClient.invalidateQueries([QUERY_GET_PRICING_INFO]);

        const hasT6Service = hasT6Values();
        const queryParams = hasT6Service ? { redirectTo: 'TOW_MILEAGE_RATES' } : undefined;
        if (hasT6Service) {
          onSave();
        }
        toIndividualScreen?.(`${updatedStartDateTime}|${updatedEndDateTime}`, queryParams);
      } catch (error: any) {
        const { response } = error;
        setNotifications({
          name: 'Error',
          message: response.data.details,
          type: 'error',
        });
      }
    } else {
      const createData: CreateServiceSetupRequestDto[] = [];
      const batchUpdateData: ServiceSetupData = { ...updatedData };
      batchUpdateData.services = [];
      updatedData.services.forEach((service) => {
        if (service.uuid && service.uuid.startsWith('fakeUuid-')) {
          createData.push({
            ...service,
            startDateTime: formData.startDateTime,
            endDateTime: formData.endDateTime,
            description: formData.description,
            duty: formData.duty,
            pricePerUnit: service.pricePerUnit,
            overridingPricePerUnit:
              service.overridingPricePerUnit === ''
                ? null
                : parseFloat(service.overridingPricePerUnit),
            priceCap: service.priceCap === '' ? null : parseFloat(service.priceCap),
            pricingType: service.pricingType as PricingTypes,
            freeUnits: parseFloat(service.freeUnits),
          });
        } else {
          const { serviceCode } = service;
          const originalServiceData = originalData.services.filter(
            (service) => service.serviceCode == serviceCode,
          );

          if (originalServiceData.length > 0 && !isEqual(originalServiceData.at(0), service)) {
            batchUpdateData.services.push(service);
          }
        }
      });
      const updateServices =
        batchUpdateData.services.length > 0
          ? await mutateUpdatesServicesSetupBatch(batchUpdateData)
          : Promise.resolve();
      const createService =
        createData.length > 0
          ? Promise.all(
              createData.map(async (service) => {
                await mutateCreateServiceSetup(service);
              }),
            )
          : Promise.resolve();
      const deleteService =
        deletedServiceItems.length > 0
          ? Promise.all(
              deletedServiceItems.map(async (service) => {
                !service.startsWith('fakeUuid-') && (await mutateDeleteServiceSetup(service));
              }),
            )
          : Promise.resolve();
      try {
        await Promise.all([deleteService, updateServices, createService]);
        queryClient.invalidateQueries([QUERY_SERVICE_SETUP_ALL]);
        queryClient.invalidateQueries([QUERY_GET_PRICING_INFO]);
        setEditMode(false);
        setIsEditingSection(false);
        setSelectedServiceItems([]);
        setDeletedServiceItems([]);

        const hasT6Service = hasT6Changes();
        if (hasT6Service) {
          onSave();
        }
      } catch (error: any) {
        const { response } = error;
        setNotifications({
          name: 'Error',
          message: response.data.details,
          type: 'error',
        });
      }
    }
  };

  const handleDiscard = () => {
    hookForm.reset();
    generalInfoReset();
    !isCreationMode && setEditMode(false);
    !isCreationMode && setIsEditingSection(false);
    setSelectedServiceItems([]);
    setDeletedServiceItems([]);
    handleResetSelect();
    if (isCreationMode) {
      navigate(-1);
    }
  };

  return (
    <Box>
      {isEditMode && (
        <Stack direction="row" mb={2} px={4} justifyContent="space-between">
          <DynamicFormDeleteAction
            title="Delete service rate"
            label="Delete service rate"
            content="Are you sure you want to delete this service rate?"
            onSubmit={onDeleteServices}
            disabled={selectedServiceItems.length === 0}
          />
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            Add Service Item
          </Button>
        </Stack>
      )}

      <ControlledFormTable<any>
        hookForm={hookForm}
        fields={fields}
        isEditMode={isEditMode}
        columns={pricingServiceRatesFields}
        fieldArrayName="services"
        stickyHeader={true}
        stickyOffset={stickyOffset}
        indexName="uuid"
        hasSelectors={true}
        onSelect={setSelectedServiceItems}
        resetTrigger={resetTrigger}
      />

      <Box sx={{ px: 4 }}>
        <DynamicExpandableFormActions
          isEditMode={isEditMode}
          save={{
            onSubmit: hookForm.handleSubmit(onSubmitAll),
            disabled: !isGeneralInfoDirty && !isServiceRatesDirty,
          }}
          discard={{
            onSubmit: handleDiscard,
          }}
        />
      </Box>

      <ServiceItemsDialog
        title="Service items"
        open={open}
        handleClose={() => setOpen(false)}
        onApprove={onAddServices}
        serviceItems={serviceItems?.filter((item) => !addedServiceItems.includes(item.code))}
      />
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
    </Box>
  );
};

export default PricingServiceRatesEditor;
