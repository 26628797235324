import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import DataTable from 'components/DataTable';
import Stack from '@mui/material/Stack';
import groupBy from 'lodash.groupby';
import { Typography, Button } from '@mui/material';
import { useMemo } from 'react';
import { DynamicExpandableFormActions } from 'components/Dynamic/DynamicExpandableFormActions';
import {
 useServiceProvidedConfig,
 useServicesProvidedColumnsConfig,
 useTroubleCodeConfig,
 useTroubleCodeColumnsConfig,
 codeColumnDef,
} from './config';
import {
 CaseServiceProvidedData,
 TroubleCodeData,
 useServicesProvidedList,
} from './ServicesProvidedQuery';
import DynamicFormDeleteAction from 'components/Dynamic/DynamicFormDeleteAction/DynamicFormDeleteAction';
import { ControlledFormTable } from 'components/ControlledForm/ControlledFormTable';
import FormDivider from 'components/Form/FormDivider/FormDivider';
import { useServicesProvided } from './hooks/useServicesProvided';
import { useServicesProvidedForm } from './hooks/useServicesProvidedForm';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import { useMileagesForm } from './hooks/useMileagesForm';
import { DynamicForm } from 'components/Dynamic/DynamicForm';

type Props = {
 caseUUID: string;
 isEditMode: boolean;
 setEditMode: (value: boolean) => void;
 setIsEditingSection: (value: boolean) => void;
 servicesProvided: CaseServiceProvidedData[];
 troubleCodes: TroubleCodeData[];
};

export default function ServicesProvidedEditor({
 caseUUID,
 isEditMode,
 setEditMode,
 setIsEditingSection,
 servicesProvided,
 troubleCodes,
}: Props) {
 const { permissions: serviceProvidedPermissions } = useServiceProvidedConfig();
 const { permissions: troubleCodePermissions } = useTroubleCodeConfig();
 const { permissions: clubUser } = useRapCoreFeaturePermissions('clubUser');

 const {
  selectedServicesProvided,
  setSelectedServicesProvided,
  setDeletedServicesProvided,
  resetTrigger,
  updateServicesProvidedMutateAsync,
  updateTroubleCodeMutateAsync,
  onAddServiceProvided,
  onDeleteServiceProvided,
  errorMapping,
  resetMutations,
 } = useServicesProvided(caseUUID);

 const { data: fullServiceList } = useServicesProvidedList(true);

 const {
  problem: problemCodes = [],
  resolution: resolutionCodes = [],
  pacesetter: pacesetterCodes = [],
 } = useMemo(() => groupBy(troubleCodes, (code) => code.type.toLowerCase()), [troubleCodes]);

 const problemAndPacesetterCodes = useMemo(
  () => problemCodes.concat(pacesetterCodes),
  [pacesetterCodes, problemCodes],
 );

 const {
  totalCost,
  troubleCodesHookForm,
  troubleCodesHandleSubmit,
  troubleCodesIsDirty,
  troubleCodesFields,
  servicesProvidedHookForm,
  servicesProvidedHandleSubmit,
  servicesProvidedIsDirty,
  servicesProvidedFields,
  servicesProvidedAppend,
  servicesProvidedRemove,
  resetForms,
 } = useServicesProvidedForm(servicesProvided, problemAndPacesetterCodes);

 const { mileagesHookForm, mileagesFields } = useMileagesForm({ caseUUID, isEditMode });

 const { troubleCodesColumns } = useTroubleCodeColumnsConfig({
  isEditMode,
  problemAndPacesetterCodes,
 });
 const { servicesProvidedColumns } = useServicesProvidedColumnsConfig({
  isEditMode,
  servicesProvided,
  totalCost,
  nationalUser: !clubUser?.read,
 });

 const reset = () => {
  setEditMode(false);
  setIsEditingSection(false);
  setSelectedServicesProvided([]);
  setDeletedServicesProvided([]);
  resetMutations();
  resetForms();
 };

 const handleDiscard = () => {
  reset();
 };

 const handleSaveConfirmation = async () => {
  const mileageFormData = mileagesHookForm.getValues();
  const { isDirty: mileageIsDirty } = mileagesHookForm.formState;

  const troubleCodesData = await new Promise<TroubleCodeData[] | null>((resolve) => {
   troubleCodesHandleSubmit((data) => resolve(data.troubleCodes))();
  });

  const servicesProvidedData = await new Promise<CaseServiceProvidedData[] | null>((resolve) => {
   servicesProvidedHandleSubmit((data) => resolve(data.servicesProvided))();
  });

  if (troubleCodesData && troubleCodesIsDirty) {
   await Promise.all(
    troubleCodesData.map((troubleCode) => updateTroubleCodeMutateAsync(troubleCode)),
   );
  }

  if (
   fullServiceList &&
   servicesProvidedData &&
   servicesProvidedData.length > 0 &&
   (servicesProvidedIsDirty || (mileageIsDirty && mileageFormData.mileageOverride))
  ) {
   const servicesProvidedDataWithName = servicesProvidedData.map((service) => {
    const serviceWithName = fullServiceList.find((field) => field.code === service.code);
    return { ...service, name: serviceWithName?.name };
   });
   await updateServicesProvidedMutateAsync({
    mileageOverride: mileageFormData.mileageOverride ? +mileageFormData.mileageOverride : undefined,
    servicesProvided: servicesProvidedDataWithName,
   });
  }

  reset();
 };

 return (
  <>
   <Box mx={-4}>
    {troubleCodes && troubleCodePermissions?.read && (
     <Stack direction="row" spacing={1} mb={1.5}>
      <Box width="50%">
       <FormDivider
        label="Trouble/Dispatch Codes"
        sx={{ px: 3, mb: 3 }}
        typographySx={{ ml: 1.25 }}
       />
       <ControlledFormTable
        hookForm={troubleCodesHookForm}
        columns={troubleCodesColumns}
        fields={troubleCodesFields}
        fieldArrayName="troubleCodes"
        isEditMode={isEditMode}
       />
      </Box>

      <Box width="50%">
       <FormDivider label="Resolution Codes" sx={{ px: 3, mb: 3 }} typographySx={{ ml: 1.25 }} />
       <DataTable rows={resolutionCodes} columns={codeColumnDef} />
      </Box>
     </Stack>
    )}
    <FormDivider label="Services" sx={{ px: 3, mb: 3, pl: 4 }} />
    {isEditMode && (
     <Stack direction="row" justifyContent="space-between" mx={2.8125} mb={2}>
      {serviceProvidedPermissions?.delete && (
       <DynamicFormDeleteAction
        title="Delete service"
        label="Delete service"
        content={`Are you sure you want to delete ${selectedServicesProvided.length} service${
         selectedServicesProvided.length > 1 ? 's' : ''
        }?`}
        onSubmit={() => onDeleteServiceProvided(servicesProvidedFields, servicesProvidedRemove)}
        disabled={selectedServicesProvided.length === 0}
       />
      )}

      {serviceProvidedPermissions?.create && (
       <Button
        variant="outlined"
        size="large"
        startIcon={<AddIcon />}
        onClick={() => onAddServiceProvided(servicesProvidedAppend)}
       >
        Add Service
       </Button>
      )}
     </Stack>
    )}
    <ControlledFormTable
     hookForm={servicesProvidedHookForm}
     columns={servicesProvidedColumns}
     fields={servicesProvidedFields}
     fieldArrayName="servicesProvided"
     indexName="uuid"
     isEditMode={isEditMode}
     onSelect={setSelectedServicesProvided}
     hasSelectors={isEditMode}
     resetTrigger={resetTrigger}
    />
    <FormDivider label="Mileage" sx={{ px: 3, mb: 3, pl: 4, mt: 3 }} />
    <Box sx={{ px: 3 }}>
     <DynamicForm
      hookForm={mileagesHookForm}
      fields={mileagesFields}
      rowSpacing={3}
      isEditMode={isEditMode}
     />
    </Box>
   </Box>
   <DynamicExpandableFormActions
    isEditMode={isEditMode}
    save={{
     onSubmit: handleSaveConfirmation,
     disabled:
      !servicesProvidedIsDirty && !troubleCodesIsDirty && !mileagesHookForm.formState.isDirty,
    }}
    discard={{
     onSubmit: handleDiscard,
    }}
   />

   {errorMapping.map(
    (error, index) =>
     error.condition && (
      <Typography key={index} color="error">
       {error.message}
      </Typography>
     ),
   )}
  </>
 );
}
