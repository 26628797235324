import { CaseGeneralInfoDto } from 'api/cases/interfaces/GeneralInfoDto';
import {
 fetchCallGeneralInfoByUUID,
 updateCallGeneralInfoByUUID,
 fetchCallAddonsByUUID,
 updateCallAddonsByUUID,
} from 'api/cases/manageCasesApi';
import { CaseGeneralInfoData } from 'features/Cases/CaseDetails/GeneralInfo/interface/CaseGeneralInfoData';
import { isEditable } from 'features/Cases/helpers';
import { useManagedMutation } from 'hooks/useReactQueryHelpers';
import { useQuery, useQueryClient } from 'react-query';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { convertPhone } from 'utils/convertPhone';
import { convertToStringLeadZero } from 'utils/convertToStringLeadZero';
import { joinValues } from 'utils/joinValues';

const QUERY_GET_CASE_GENERAL_INFO_BY_UUID = 'QUERY_GET_CASE_GENERAL_INFO_BY_UUID';
const QUERY_UPDATE_CASE_GENERAL_INFO_BY_UUID = 'QUERY_GET_CASE_GENERAL_INFO_BY_UUID';
const QUERY_GET_CASE_ADDONS_BY_UUID = 'QUERY_GET_CASE_ADDONS_BY_UUID';
const QUERY_UPDATE_CASE_ADDONS_BY_UUID = 'QUERY_UPDATE_CASE_ADDONS_BY_UUID';
/**
 * Map internal data to backend dto.
 * Used for update General Info.
 *
 * @param data
 */
const mapDataToDto = (data: Partial<CaseGeneralInfoData>): Partial<CaseGeneralInfoDto> => {
 const [clientName, clientId] = data.clientName ? data.clientName.split(' - ') : '';

 return {
  ...data,
  rapCaseId: data.rapCaseId ? parseInt(data.rapCaseId) : undefined,
  clientName,
  clientId: clientId ? parseInt(clientId) : undefined,
 };
};

export const mapDtoToData = (dto: CaseGeneralInfoDto): CaseGeneralInfoData => ({
 rbpCaseUuid: dto.uuid,
 callStatus: dto.callStatus,
 rbpCaseStatus: dto.rbpCaseStatus,
 isEditable: isEditable(dto.rbpCaseStatus),

 // general info
 rapCaseId: convertToStringLeadZero(dto.rapCaseId, 8),
 callKey: dto.callKey,
 rapCallId: dto.rapCallId,
 rapProgramCode: dto.rapProgramCode,
 clubCode: convertToStringLeadZero(dto.clubCode, 3),
 clubName: dto.clubName,
 clientName: joinValues([dto.clientName, dto.clientId], ' - '),
 programId: dto.programId,
 programName: dto.programName,
 agentId: dto.agentId,
 callCenterPhoneNumber: convertPhone(dto.callCenterPhoneNumber),
 rapCallCreatedDateTime: convertUTCToDateTimeString(dto.rapCallCreatedDateTime),
 rapCallUtcCreatedDateTime: convertUTCToDateTimeString(dto.rapCallUtcCreatedDateTime),
 rapCallUtcCreatedDateTimeOriginal: dto.rapCallUtcCreatedDateTime,
 channelType: dto.channelType,
 paymentResponsible: dto.paymentResponsible,
 servicePriorityCode: dto.servicePriorityCode,
 accident: dto.accident,
 mileageOverride: dto.mileageOverride,

 // vehicle
 vehicleVin: dto.vehicleVin,
 vehicleMake: dto.vehicleMake,
 vehicleYear: dto.vehicleYear,
 vehicleModel: dto.vehicleModel,
 vehicleColor: dto.vehicleColor,
 vehicleOdometer: dto.vehicleOdometer,
 serviceNumberOfPassengers: dto.serviceNumberOfPassengers,
 serviceDuty: dto.serviceDuty,
 vehicleUnattendedVehicle: dto.vehicleUnattendedVehicle,
 vehicleLicenseState: dto.vehicleLicenseState,
 vehicleTag: dto.vehicleTag,

 // customer info
 contactFirstName: dto.contactFirstName,
 contactLastName: dto.contactLastName,
 contactPhoneNumber: convertPhone(dto.contactPhoneNumber),
 contactPhoneExtension: dto.contactPhoneExtension,
 contactPhoneType: dto.contactPhoneType,
 tripInterruption: dto.tripInterruption,
 goodwill: dto.goodwill,
});

export function useGetCaseGeneralInfoByUUID(uuid: string, enabled: boolean) {
 return useQuery(
  [QUERY_GET_CASE_GENERAL_INFO_BY_UUID, uuid],
  () => fetchCallGeneralInfoByUUID(uuid),
  { keepPreviousData: false, select: mapDtoToData, enabled },
 );
}

export const useUpdateCaseGeneralInfoByUUID = (uuid: string) => {
 const queryClient = useQueryClient();
 return useManagedMutation({
  mutationFn: (data: Partial<CaseGeneralInfoData>) =>
   updateCallGeneralInfoByUUID(uuid, mapDataToDto(data)),
  onSuccess: () => {
   queryClient.invalidateQueries([QUERY_UPDATE_CASE_GENERAL_INFO_BY_UUID]);
  },
 });
};

export const useGetCaseAddonsByUUID = (uuid: string, enabled: boolean) => {
 return useQuery(
  [QUERY_GET_CASE_ADDONS_BY_UUID, uuid],
  () =>
   fetchCallAddonsByUUID(uuid)
    .then((res) => res)
    .catch(() => ({})),
  { keepPreviousData: false, enabled },
 );
};

export const useUpdateCaseAddonsByUUID = (uuid: string) => {
 const queryClient = useQueryClient();
 return useManagedMutation({
  mutationFn: (data: Partial<{ [key: string]: string }>) => updateCallAddonsByUUID(uuid, data),
  onSuccess: () => {
   queryClient.invalidateQueries([QUERY_UPDATE_CASE_ADDONS_BY_UUID]);
  },
 });
};
