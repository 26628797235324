import { useQuery, useQueryClient } from 'react-query';
import {
 updateServicesProvided,
 fetchCaseServicesProvided,
 fetchTroubleCodes,
 updateTroubleCodeByUUID,
} from 'api/cases/manageCasesApi';
import { fetchServicesProvided, fetchServicesProvidedByCode } from 'api/servicesProvided';
import { ServiceProvidedDto } from 'api/servicesProvided/interfaces';
import { CaseServiceProvidedDto, TroubleCodeTypes } from 'api/cases/interfaces/ServiceProvidedDto';
import { useManagedMutation } from 'hooks/useReactQueryHelpers';
import { TroubleCodeDto } from 'api/cases/interfaces/TroubleCodeDto';
import { PriceType } from 'api/cases/enums/PriceType';
import { BatchUpsertServiceProvidedDto } from 'api/cases/interfaces/BatchUpsertServiceProvidedDto';
import { QUERY_CASES_BY_UUID, QUERY_MANAGE_CASE_ERRORS_BY_UUID } from 'features/Cases/queries';

export interface CaseServiceProvidedData {
 uuid?: string | null;
 unitCount: number;
 unitOfMeasure: 'ML' | 'EA' | '';
 code: string;
 cost: number;
 name?: string;
 priceType?: PriceType;
}

export enum TroubleCodes {
 '7A' = 'Lockout - Key Delivery',
 '7B' = 'Lockout - Locksmith',
 'T0' = 'GOA',
 'T1' = 'Flat w/Spare',
 'T3' = 'Battery',
 'T5' = 'Fuel',
 'T6' = 'Tow',
 'T7' = 'Lockout',
 'T8' = 'Extrication',
 'T9' = 'Miscellaneous',
}

export interface TroubleCodeData {
 uuid: string;
 type: string;
 code: keyof typeof TroubleCodes | string;
 description: string;
}

type Props = {
 caseId: string;
 enabled: boolean;
 types?: TroubleCodeTypes[];
};

const mapCaseServicesProvidedDtoToData = (
 data: CaseServiceProvidedDto[],
): CaseServiceProvidedData[] => {
 return data.map((row) => {
  return {
   uuid: row.uuid,
   unitCount: row.unitCount,
   unitOfMeasure: row.code === 'T6' ? 'ML' : 'EA',
   code: row.code,
   cost: row.cost,
   priceType: row.priceType,
   name: row.name,
  };
 });
};

function mapTroubleCodesDtoToData(data: TroubleCodeDto[]): TroubleCodeData[] {
 return data.map((dto) => {
  const troubleCode = dto.code as keyof typeof TroubleCodes;

  return {
   ...dto,
   code: troubleCode,
   description: TroubleCodes[troubleCode] || dto.description,
  };
 });
}

const mapTroubleCodeDataToDto = (data: Partial<TroubleCodeData>): Partial<TroubleCodeDto> => {
 const { code, description } = data;
 return {
  code,
  description,
 };
};

export function useCaseServiceTroubleCodes({ caseId, types, enabled }: Props) {
 return useQuery(['caseServiceTroubleCodes', caseId], () => fetchTroubleCodes(caseId, types), {
  enabled,
  select: mapTroubleCodesDtoToData,
 });
}

export function useUpdateTroubleCode(caseId: string) {
 const queryClient = useQueryClient();

 return useManagedMutation({
  mutationFn: (troubleCodeData: Partial<TroubleCodeData>) =>
   updateTroubleCodeByUUID(
    caseId,
    troubleCodeData.uuid || '',
    mapTroubleCodeDataToDto(troubleCodeData),
   ),
  onSuccess: () => {
   queryClient.invalidateQueries([QUERY_CASES_BY_UUID]);
   queryClient.invalidateQueries([QUERY_MANAGE_CASE_ERRORS_BY_UUID, caseId]);
   queryClient.invalidateQueries(['caseServiceTroubleCodes']);
  },
 });
}

export function useCaseServicesProvided(callId: string, enabled = true) {
 return useQuery(['caseServicesProvided', callId], () => fetchCaseServicesProvided(callId), {
  enabled,
  select: mapCaseServicesProvidedDtoToData,
 });
}

export function useServicesProvidedList(enabled = true) {
 return useQuery<ServiceProvidedDto[]>(
  ['servicesProvided'],
  () => fetchServicesProvided().then((res) => res.content as ServiceProvidedDto[]),
  { enabled },
 );
}

export function useServicesProvidedByCode(codes: string[], enabled = true) {
 return useQuery<CaseServiceProvidedDto[]>(
  ['servicesProvided', codes],
  () => fetchServicesProvidedByCode(codes).then((res) => res.content as CaseServiceProvidedDto[]),
  { enabled },
 );
}

export function useUpdateServicesProvided(caseId: string) {
 const queryClient = useQueryClient();
 return useManagedMutation({
  mutationFn: (batchUpsertServiceProvidedDto: BatchUpsertServiceProvidedDto) =>
   updateServicesProvided(caseId, batchUpsertServiceProvidedDto),
  onSuccess: () => {
   queryClient.invalidateQueries([QUERY_CASES_BY_UUID]);
   queryClient.invalidateQueries([QUERY_MANAGE_CASE_ERRORS_BY_UUID, caseId]);
   queryClient.invalidateQueries(['caseServicesProvided', caseId]);
  },
 });
}
