import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  addServicesSetupBatch,
  addServiceSetup,
  updateServicesSetupBatch,
  deleteServiceSetup,
  EntityTypes,
  DutyTypes,
  fetchPricingInfo,
  PricingRecordDto,
  CreateServiceSetupBatchRequestDto,
  fetchServicesProvided,
  fetchAllServicesSetup,
  CreateServiceSetupRequestDto,
  UpdateServiceSetupBatchRequestDto,
  UpdateServiceSetupRequestItemDto,
  BaseCreateServiceSetupRequestDto,
  PricingTypes,
  updatePricingGeneralInfo,
  UpdatePricingDto,
} from 'api/setup/manageServicesSetupApi';
import {
  addTowsSetupBatch,
  CreateTowSetupBatchRequestDto,
  ManageTowsSetupApi,
  updateTowsSetupBatch,
  fetchAllTowsSetup,
  deleteTowsSetup,
  addTowSetup,
  CreateTowSetupRequestDto,
  TowTypes,
  BaseCreateTowSetupRequestDto,
  UpdateTowSetupRequestItemDto,
  UpdateTowSetupBatchRequestDto,
} from 'api/setup/manageTowSetupApi';
import { CRUDPageableReactQuery, useManagedMutation } from 'hooks/useReactQueryHelpers';

const QUERY_GET_SERVICE_ITEMS = 'QUERY_GET_SERVICE_ITEMS';
export const QUERY_GET_PRICING_INFO = 'QUERY_GET_PRICING_INFO';
export const QUERY_SERVICE_SETUP_ALL = 'QUERY_SERVICE_SETUP_ALL';
export const QUERY_TOWS_SETUP_ALL = 'QUERY_TOWS_SETUP_ALL';

export interface TowSetupData {
  startDateTime: string;
  endDateTime: string;
  description: string;
  duty: DutyTypes;
  tows: TowData[];
}

export interface TowData {
  uuid: string;
  entityType: EntityTypes;
  entityUuid: string;
  startMileage: string;
  endMileage: string;
  type: TowTypes;
  pricePerUnit: string;
  priceCap: string;
}

export interface ServiceSetupData {
  startDateTime: string;
  endDateTime: string;
  description: string;
  duty: DutyTypes;
  services: ServiceData[];
}
export interface ServiceData {
  uuid: string;
  serviceCode: string;
  serviceName: string;
  billable: boolean;
  payable: boolean;
  pricingType: string;
  pricePerUnit: number;
  serviceProvidedUuid: string;
  entityType: EntityTypes;
  entityUuid: string;
  freeUnits: string;
  overridingPricePerUnit: string;
  priceCap: string;
  tooltip?: string;
}

export const useGetAllServiceSetup = (
  entityType: EntityTypes,
  entityUuid: string,
  startDateTime: string,
  endDateTime: string,
  enabled: boolean,
  duty?: DutyTypes,
) => {
  return useQuery(
    [QUERY_SERVICE_SETUP_ALL, duty, entityType, entityUuid, startDateTime, endDateTime, enabled],
    () =>
      fetchAllServicesSetup(entityType, entityUuid, startDateTime, endDateTime, duty).then(
        (res) => res.content,
      ),
    { enabled, keepPreviousData: false },
  );
};

export const useGetAllTowsSetup = (
  entityType: EntityTypes,
  entityUuid: string,
  startDateTime: string,
  endDateTime: string,
  enabled: boolean,
  duty: DutyTypes,
) => {
  return useQuery(
    [QUERY_TOWS_SETUP_ALL, duty, entityType, entityUuid, startDateTime, endDateTime, enabled],
    () =>
      fetchAllTowsSetup(entityType, entityUuid, startDateTime, endDateTime, duty).then(
        (res) => res.content,
      ),
    { enabled, keepPreviousData: false },
  );
};

export const TowsSetupQuery = CRUDPageableReactQuery('QUERY_TOWS_SETUP', ManageTowsSetupApi);

export const useGetServiceItems = () => {
  return useQuery(
    [QUERY_GET_SERVICE_ITEMS],
    () => fetchServicesProvided().then((res) => res.content),
    {
      keepPreviousData: false,
    },
  );
};

const transformServiceDataToBaseCreateServiceSetupRequestDto = (
  serviceData: ServiceData,
): BaseCreateServiceSetupRequestDto => {
  return {
    serviceProvidedUuid: serviceData.serviceProvidedUuid,
    entityType: serviceData.entityType,
    entityUuid: serviceData.entityUuid,
    pricePerUnit: serviceData.pricePerUnit,
    payable: serviceData.payable,
    billable: serviceData.billable,
    pricingType: serviceData.pricingType as PricingTypes,
    freeUnits: parseFloat(serviceData.freeUnits),
    priceCap: serviceData.priceCap === '' ? null : parseFloat(serviceData.priceCap),
    overridingPricePerUnit:
      serviceData.overridingPricePerUnit === ''
        ? null
        : parseFloat(serviceData.overridingPricePerUnit),
  };
};

const transformServiceSetupDataToCreateServiceSetupBatchRequestDto = (
  data: ServiceSetupData,
): CreateServiceSetupBatchRequestDto => {
  const transformedServices = data.services.map((serviceData) =>
    transformServiceDataToBaseCreateServiceSetupRequestDto(serviceData),
  );

  return {
    startDateTime: data.startDateTime,
    endDateTime: data.endDateTime,
    description: data.description,
    duty: data.duty,
    services: transformedServices,
  };
};

export const useCreateServicesSetupBatch = () => {
  return useMutation((data: ServiceSetupData) => {
    const transformedData = transformServiceSetupDataToCreateServiceSetupBatchRequestDto(data);
    return addServicesSetupBatch(transformedData);
  });
};

const transformServiceDataToUpdateServiceSetupRequestItemDto = (
  serviceData: ServiceData,
): UpdateServiceSetupRequestItemDto => {
  return {
    serviceProvidedUuid: serviceData.serviceProvidedUuid,
    entityType: serviceData.entityType,
    entityUuid: serviceData.entityUuid,
    pricePerUnit: serviceData.pricePerUnit,
    payable: serviceData.payable,
    billable: serviceData.billable,
    pricingType: serviceData.pricingType as PricingTypes,
    uuid: serviceData.uuid,
    freeUnits: parseFloat(serviceData.freeUnits),
    priceCap: serviceData.priceCap === '' ? null : parseFloat(serviceData.priceCap),
    overridingPricePerUnit:
      serviceData.overridingPricePerUnit === ''
        ? null
        : parseFloat(serviceData.overridingPricePerUnit),
  };
};

const transformServiceSetupDataToUpdateServiceSetupBatchRequestDto = (
  data: ServiceSetupData,
): UpdateServiceSetupBatchRequestDto => {
  const transformedServices = data.services.map((serviceData) =>
    transformServiceDataToUpdateServiceSetupRequestItemDto(serviceData),
  );

  return {
    duty: data.duty,
    services: transformedServices,
  };
};

export const useUpdateServicesSetupBatch = () => {
  return useMutation((data: ServiceSetupData) => {
    const transformedData = transformServiceSetupDataToUpdateServiceSetupBatchRequestDto(data);
    return updateServicesSetupBatch(transformedData);
  });
};

export const useCreateServiceSetup = () => {
  return useMutation((data: CreateServiceSetupRequestDto) => addServiceSetup(data));
};

export const useDeleteServiceSetup = () => {
  return useMutation((uuid: string) => deleteServiceSetup(uuid));
};

const transformTowDataToBaseCreateTowSetupRequestDto = (
  towData: TowData,
): BaseCreateTowSetupRequestDto => {
  return {
    entityType: towData.entityType,
    entityUuid: towData.entityUuid,
    startMileage: parseFloat(towData.startMileage),
    endMileage: parseFloat(towData.endMileage),
    type: towData.type as TowTypes,
    pricePerUnit: parseFloat(towData.pricePerUnit),
    priceCap: towData.priceCap === '' ? null : parseFloat(towData.priceCap),
  };
};

const transformTowSetupDataToCreateTowSetupBatchRequestDto = (
  data: TowSetupData,
): CreateTowSetupBatchRequestDto => {
  const transformedTows = data.tows.map((towData) =>
    transformTowDataToBaseCreateTowSetupRequestDto(towData),
  );

  return {
    startDateTime: data.startDateTime,
    endDateTime: data.endDateTime,
    description: data.description,
    duty: data.duty,
    tows: transformedTows,
  };
};

export const useCreateTowsSetupBatch = () => {
  return useMutation((data: TowSetupData) => {
    const transformedData = transformTowSetupDataToCreateTowSetupBatchRequestDto(data);
    return addTowsSetupBatch(transformedData);
  });
};

const transformTowDataToUpdateTowSetupRequestItemDto = (
  towData: TowData,
): UpdateTowSetupRequestItemDto => {
  return {
    entityType: towData.entityType,
    entityUuid: towData.entityUuid,
    startMileage: parseFloat(towData.startMileage),
    endMileage: parseFloat(towData.endMileage),
    type: towData.type as TowTypes,
    pricePerUnit: parseFloat(towData.pricePerUnit),
    priceCap: towData.priceCap === '' ? null : parseFloat(towData.priceCap),
    uuid: towData.uuid,
  };
};

const transformTowSetupDataToUpdateTowSetupBatchRequestDto = (
  data: TowSetupData,
): UpdateTowSetupBatchRequestDto => {
  const transformedTows = data.tows.map((towData) =>
    transformTowDataToUpdateTowSetupRequestItemDto(towData),
  );

  return {
    tows: transformedTows,
  };
};

export const useUpdateTowsSetupBatch = () => {
  return useMutation((data: TowSetupData) => {
    const transformedData = transformTowSetupDataToUpdateTowSetupBatchRequestDto(data);
    return updateTowsSetupBatch(transformedData);
  });
};

export const useCreateTowSetup = () => {
  return useMutation((data: CreateTowSetupRequestDto) => addTowSetup(data));
};

export const useDeleteTowSetup = () => {
  return useMutation((uuid: string) => deleteTowsSetup(uuid));
};

export const useGetPricingInfo = (entityType: EntityTypes, entityUuid = '') => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<PricingRecordDto[]>(
    [QUERY_GET_PRICING_INFO, entityType, entityUuid],
    () => fetchPricingInfo(entityType, entityUuid),
    { keepPreviousData: false, enabled: !!entityUuid },
  );
};

export const useUpdatePricingGeneralInfo = () => {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (data: UpdatePricingDto) => updatePricingGeneralInfo(data),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_GET_PRICING_INFO]);
    },
  });
};
