import { ServiceSetupDto } from 'api/setup/manageServicesSetupApi';
import { ServiceSetupData } from '../PricingSetupQuery';
import { serviceCodeComparator } from 'utils/getComparator';
import { TooltipCategories } from 'components/Form/FormInputTooltip';

/**
 * Transforms an array of ServiceSetupDto objects into an UpdateServiceSetupBatchRequestData object.
 *
 * @param data An array of ServiceSetupDto objects.
 * @returns An UpdateServiceSetupBatchRequestData object containing the transformed data.
 */
export const getUpdateServiceSetupData = (data: ServiceSetupDto[]): ServiceSetupData => {
  const [firstService] = data;
  const { startDateTime, endDateTime, description, duty } = firstService;

  const services = data
    .map((item) => ({
      serviceName: item.serviceProvided.name,
      serviceCode: item.serviceProvided.code,
      updatedByUserId: item.updatedByUserId,
      serviceProvidedUuid: item.serviceProvided.uuid,
      entityType: item.entityType,
      entityUuid: item.entityUuid,
      pricePerUnit: item.pricePerUnit,
      priceCap: item.priceCap === null ? '' : item.priceCap?.toString(),
      overridingPricePerUnit:
        item.overridingPricePerUnit === null ? '' : item.overridingPricePerUnit?.toString(),
      freeUnits: item.freeUnits?.toString(),
      payable: item.payable,
      billable: item.billable,
      uuid: item.uuid,
      pricingType: item.pricingType,
      tooltip: item.serviceProvided.code === 'T6' ? TooltipCategories.t6FieldOnPricingSetup : '',
    }))
    .sort((a, b) => serviceCodeComparator(a, b, 'serviceCode'));

  return { startDateTime, endDateTime, description, duty, services };
};
