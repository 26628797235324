import { BreakdownLocationDto } from 'api/cases/interfaces/BreakdownLocationDto';
import { CaseErrorDto, ResolutionTypes } from 'api/cases/interfaces/CaseErrorDto';
import { useFeaturePermission, useFieldPermission } from 'auth';
import { useTableFieldPermission } from 'auth/permissions/useTableFieldPermission';
import { ControlledFormTableColumn } from 'components/ControlledForm/ControlledFormTable/ControlledFormTable';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { TowDestinationRecord } from 'features/Cases/interfaces/CaseViewPanels';
import sumBy from 'lodash.sumby';
import * as Yup from 'yup';

export const CASE_BREAKDOWN_LOCATION_PERMISSION = 'BreakdownLocationDto';
export const CASE_TOW_DESTINATIONS_PERMISSION = 'TowDestinationDto';

interface Props {
 isEditMode: boolean;
}

export const breakdownLocationFields: DynamicField<BreakdownLocationDto>[] = [
 {
  label: 'Composite Address',
  name: 'compositeAddress',
  colspan: 12,
 },
 {
  label: 'Street Number',
  name: 'streetNumber',
 },
 {
  label: 'Street Name',
  name: 'street',
 },
 {
  label: 'City',
  name: 'city',
 },
 {
  label: 'State',
  name: 'state',
 },
 {
  label: 'Zip Code',
  name: 'zipCode',
 },
 {
  label: 'Latitude',
  name: 'latitude',
 },
 {
  label: 'Longitude',
  name: 'longitude',
 },
 {
  label: 'Street Direction',
  name: 'streetDirection',
 },
 {
  label: 'Mile Marker',
  name: 'mileMarker',
 },
 {
  label: 'Cross Street',
  name: 'crossStreet',
 },
 {
  label: 'Landmark',
  name: 'landmark',
  colspan: 12,
 },
];

export function useBreakdownLocationConfig({ isEditMode }: Props) {
 return {
  breakdownLocationFields: useFieldPermission({
   fields: breakdownLocationFields,
   table: CASE_BREAKDOWN_LOCATION_PERMISSION,
   isEditMode,
  }),
 };
}

export function useBreakdownLocationPermissions() {
 return { permissions: useFeaturePermission({ table: CASE_BREAKDOWN_LOCATION_PERMISSION }) };
}

export const towDestinationColumns: ControlledFormTableColumn<Partial<TowDestinationRecord>>[] = [
 { id: 'locationName', label: 'Location Name', fieldType: DynamicFieldType.TEXT },
 {
  id: 'addressType',
  label: 'Location Type',
  fieldType: DynamicFieldType.SELECT,
  options: [
   { label: 'Physical', value: 'PHYSICAL' },
   { label: 'Mailing', value: 'MAILING' },
   { label: 'Unknown', value: 'UNKNOWN' },
  ],
  Footer: () => 'Total Tow Miles:',
  width: 150,
 },
 {
  id: 'computedTowMiles',
  label: 'Computed Tow Miles',
  fieldType: DynamicFieldType.TEXT,
  Footer: (rows) => sumBy(rows, (row) => parseFloat(row.computedTowMiles || '')),
 },
 { id: 'address', label: 'Address', fieldType: DynamicFieldType.TEXT },
 { id: 'cityStateZip', label: 'City / State / Zip', fieldType: DynamicFieldType.TEXT },
 { id: 'fullPhoneNumber', label: 'Phone Number', fieldType: DynamicFieldType.TEXT },
 { id: 'dealerId', label: 'Dealer ID', fieldType: DynamicFieldType.TEXT },
 { id: 'landmark', label: 'Landmark', fieldType: DynamicFieldType.TEXT },
];

export function useTowDestinationConfig({ isEditMode }: Props) {
 return {
  towDestinationColumns: useTableFieldPermission({
   columns: towDestinationColumns,
   table: CASE_TOW_DESTINATIONS_PERMISSION,
   isCreationMode: isEditMode,
  }),
 };
}

export function useTowDestinationPermissions() {
 return {
  permissions: useFeaturePermission({ table: CASE_TOW_DESTINATIONS_PERMISSION }),
 };
}

export const towDestinationValidationSchema = Yup.object().shape({
 towDestination: Yup.array().of(
  Yup.object().shape({
   locationName: Yup.string(),
   addressType: Yup.string().required('Location type is required'),
   computedTowMiles: Yup.number().nullable().optional(),
   address: Yup.string().required('Address is required'),
   // TODO: ADD Zip to this validation when data is consistently returned from the API
   cityStateZip: Yup.string()
    .required('City, State, and Zip are required')
    .matches(
     /^([\w\s-]+),\s([A-Za-z\s]+)\s([A-Za-z0-9]{5}|[A-Za-z0-9]{6})?$/,
     'Invalid format. Must be in the format "City, State Zip"',
    ),
   fullPhoneNumber: Yup.string()
    .notRequired()
    .test(
     'phone-number-format',
     'Invalid format. Must be in the format 123 4567890, or 4567890',
     (value) => {
      if (!value) return true; // Allow empty value
      return /^(\d{3}\s)?\d{7}$/.test(value);
     },
    ),
   dealerId: Yup.string(),
   landmark: Yup.string(),
  }),
 ),
});

export const findCaseErrorByCodeAndResolutionType = (
 caseErrorData: CaseErrorDto[],
 code: string,
 resolutionType: ResolutionTypes,
) => {
 return caseErrorData.some((obj) => obj.code === code && obj.resolutionType === resolutionType);
};
